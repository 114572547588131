<template>
  <div v-viewer="options" class="d-flex flex-wrap">
    <template v-for="{ source, thumbnail } in images">
      <v-hover v-slot="{ hover }" :key="source" v-if="source != null">
        <div class="profile cell">
          <img
            :src="thumbnail"
            :data-source="source"
            class="image"
            alt="Not available"
          />
          <v-btn
            @click="onRemove(source)"
            class="remove_button"
            fab
            x-small
            v-show="hover && editable"
          >
            <v-icon v-text="'mdi-close'" size="16" />
          </v-btn>
        </div>
      </v-hover>
    </template>
    <div class="cell" @click="onAddPhoto" v-if="images.length < 12 && editable">
      <div class="add_button h-100">
        <v-icon v-text="'mdi-plus'" />
      </div>
    </div>
    <v-progress-linear
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="3"
      v-if="loading"
    />
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
import { mapActions } from "vuex";
import ConfirmDeleteDialog from "../common/ConfirmDeleteDialog.vue";

Vue.use(Viewer, {
  debug: true,
  defaultOptions: {
    zIndex: 9999,
  },
});

export default {
  components: { ConfirmDeleteDialog },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    imageData: Array,
    onUpdatedImage: Function,
  },
  data() {
    return {
      options: {
        toolbar: true,
        scalable: false,
        title: false,
        url: "data-source",
      },
      images: [],
      deleteDialog: false,
      errorMessage: null,
      snackbar: false,
      selected: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions("need", {
      addPhoto: "addPhoto",
    }),
    toggleToolbar(toolbar) {
      // this.options = Object.assign({}, this.options, {toolbar})
      this.options.toolbar = toolbar;
    },
    show() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    onAddPhoto() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.onUploadFile(e.target.files[0]);
      }
    },
    onUploadFile(selectedImage) {
      var formData = new FormData();
      if (selectedImage) {
        formData.append("image", selectedImage);
      } else {
        return;
      }
      this.loading = true;

      this.addPhoto(formData)
        .then((data) => {
          this.loading = false;
          this.images.push(data);
          this.onUpdatedImage(this.images);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.images = this.images.filter((i) => i.source != this.selected);
      this.onUpdatedImage(this.images);
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onRemove(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    loadImage() {
      this.images = this.imageData ? this.imageData : [];
    },
  },
  mounted() {
    this.loadImage();
  },
  watch: {
    imageData() {
      this.loadImage();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.cell {
  width: 33%;
  cursor: pointer;
  padding: 10px;
  display: inline-block;
}
.image {
  width: 100%;
}
.profile {
  overflow: visible;
  position: relative;
}
.add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px lightgray;
  border-radius: 5px;
  min-height: 80px;
  min-width: 120px;
}
.remove_button {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>

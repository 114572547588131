<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label">Shift Date/Time* </label>
      <v-menu
        v-model="date_picker"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :top="$vuetify.breakpoint.xs"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="timeStr"
            label="MM/DD/YYYY 00:00 am"
            :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
            solo
            flat
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            autocomplete="off"
            :error-messages="timeMessage"
            @input="timeMessage = null"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          scrollable
          class="date-picker"
          :min="minDate"
        >
          <div style="height: 36px" />
        </v-date-picker>
        <v-time-picker v-model="startTime" scrollable class="time-picker">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="date_picker = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="onSaveDate(startTime)">
            OK
          </v-btn>
        </v-time-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label">Shift Hours* </label>
      <v-text-field
        v-model="hours"
        label="Example: 4 or 3.5"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
        type="number"
        autocomplete="off"
        :error-messages="hourMessage"
        @input="hourMessage = null"
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"
        >Shift Capacity*
        <tip-icon>
          Enter the number of volunteer spots available.
        </tip-icon>
      </label>
      <v-text-field
        v-model="capacity"
        label="Example: 30"
        :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'"
        solo
        flat
        outlined
        dense
        type="number"
        autocomplete="off"
        :error-messages="capacityMessage"
        @input="capacityMessage = null"
      />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-baseline"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <label class="title-label"> </label>
      <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-5'">
        <v-btn dark color="primary" @click="addShift" class="mb-5"
          >Add Shift</v-btn
        >
        <div v-for="shift in custom_shifts" :key="shift.date">
          <v-icon @click="onRemove(shift)" class="mr-1">mdi-close</v-icon>
          <span>{{ getFormattedString(shift) }}</span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
import dateFormat from "dateformat";
export default {
  components: { TipIcon },
  props: {
    need: Object,
  },
  data() {
    return {
      timeStr: "",
      hours: "",
      capacity: "",
      startDate: null,
      date_picker: false,
      startTime: null,
      timeMessage: null,
      hourMessage: null,
      capacityMessage: null,
      custom_shifts: [],
      minDate: "2021-01-01",
    };
  },
  methods: {
    onSaveDate() {
      if (!this.startTime && !this.startDate) return;
      var date = Date.parse(this.startDate + " " + this.startTime);
      this.timeStr = dateFormat(date, "mm/dd/yyyy hh:MM TT");
      this.date_picker = false;
    },
    addShift() {
      if (!this.timeStr)
        this.timeMessage = "The Date/Time fields are required to add a shift.";
      if (!this.hours)
        this.hourMessage = "The hour fields are required to add a shift.";
      if (!this.capacity)
        this.capacityMessage =
          "The capacity fields are required to add a shift.";
      if (!this.timeStr || !this.hours || !this.capacity) {
        return;
      }
      if (!this.need.custom_shifts) this.need.custom_shifts = [];

      var shift = this.need.custom_shifts.find((s) => s.date == this.timeStr);
      console.log(shift);
      if (shift) {
        this.timeMessage = "Could not add same time slot";
      } else {
        this.need.custom_shifts.push({
          date: this.timeStr,
          hours: this.hours,
          capacity: this.capacity,
        });
        this.custom_shifts = [...this.need.custom_shifts];
        this.timeStr = "";
        this.hours = "";
        this.capacity = "";
      }
    },
    onRemove(shift) {
      this.need.custom_shifts = this.need.custom_shifts.filter(
        (s) =>
          s.date != shift.date ||
          s.capacity != shift.capacity ||
          s.hours != shift.hours
      );
      this.custom_shifts = [...this.need.custom_shifts];
    },
    getFormattedString(shift) {
      var date = Date.parse(shift.date);
      return (
        dateFormat(date, "mmm dd, yyyy 'at' hh:mm tt 'for '") +
        shift.hours +
        " hours with " +
        shift.capacity +
        " slots."
      );
    },
  },
  mounted() {
    this.minDate = dateFormat(new Date(), "yyyy-mm-dd");
  },
  watch: {
    timeStr(value) {
      if (value) {
        this.timeMessage = null;
      }
    },
  },
};
</script>
<style scoped>
.title-label {
  width: 200px;
}
.date-picker {
  border-radius: 4px 0 0 4px;
}
.time-picker {
  border-radius: 0 4px 4px 0;
}
.date-picker :deep(.v-picker__title) {
  max-height: 87px;
}
.time-picker :deep(.v-picker__title) {
  max-height: 87px;
}
</style>
